import React from "react";
import { Helmet } from "react-helmet";
import { Link, StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import {
  IoTimer,
  IoPaperPlane,
  IoReceipt,
  IoBarChart,
  IoCheckmarkCircleOutline,
} from "react-icons/io5";

import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../../components/util";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Image from "../../components/image";

import HeroProductImage from "../../images/home/hero-product-image.png";
import ClockIcon from "../../images/home/clock-icon.svg";
import ReceiptIcon from "../../images/home/receipt-icon.svg";
import SendIcon from "../../images/home/send-icon.svg";
import ReportIcon from "../../images/home/report-icon.svg";
import Dashboard from "../../images/home/Invoxy_Dashboard_Info.svg";
import MiddleOffice from "../../images/home/Invoxy_MiddleOfficeFit.svg";
import Projection from "../../images/home/Invoxy_Projection.svg";
import Time from "../../images/home/Invoxy_Time.svg";
import APositiveLogo from "../../images/logos/Other/APositive_White_Full.svg";
import BullhornLogo from "../../images/logos/Other/Bullhorn_White_Full.svg";
import JobAdderLogo from "../../images/logos/Other/JobAdder_White_Full.svg";
import EmploymentHeroLogo from "../../images/logos/Other/EmploymentHero_Full_White.svg";
import PayHeroLogo from "../../images/logos/PayHero/PayHero_Full_White.svg";
import PowerBILogo from "../../images/logos/Other/PowerBI_White_Full.svg";
import XeroLogo from "../../images/logos/Xero/Xero_White_Single.svg";
import BackgroundImage from "gatsby-background-image";


const HeroContainer = styled(Wrapper)`
  height: 750px;
  display: flex;
  max-height: calc(100vh - 200px);
  align-items: center;
  color: white;
  h1{
    font-size: 28px;
    font-weight: 300 !important;
  }
  h2{
    font-weight: 400;
    font-size: 72px !important;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      .hero-product-image{
        display: none;
      }
  }
  @media (max-height: 750px) {
    max-height: none;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 600px;
    max-height: none;

    margin-top: 40px;
    background-image: none;
    background-color: #3F7157;
    h1{
      font-size: 24px;
      margin-bottom: 50px;
    }
    h2{
      font-size: 58px !important;
    }
  }
}
`;

const CheckmarkFeature = styled(Row)`
  // padding: 0 30px 0px 10px;
  svg {
    flex: 0 0 auto;
    color: ${(props) => props.theme.colours.green};
    font-size: 20px;
  }

  p {
    margin-bottom: 0;
    flex: 1 auto;
  }
`;

const LogoRow = styled(Row)`
margin-top: 50px;
  > div {
    width: calc(100% / 7);

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 100%;
      margin-bottom: 20px;
    }

    img {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 140px;
      max-height: 60px;
    }
  }
`;

const IndexPage = (props) => (
  <Layout>
    <Seo
      title="Karmly | Contingent Workforce Billing and Timesheet Software"
      description="Billing and timesheet software for staffing agencies. Karmly is everything you need for managing temporary workers, after you’ve made the placement."
      pathname={props.location.pathname}
    />
    <Helmet>
      <script type="application/ld+json">
        {`
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "url": "${process.env.GATSBY_SITE_URL}",
              "name": "FlexiTime",
              "legalName": "FlexiTime Limited",
              "description": "Billing and timesheet software for staffing agencies. Karmly is everything you need for managing temporary workers, after you've made the placement.",
              "logo": "${process.env.GATSBY_SITE_URL +
          `/metaImages/Invoxy_SchemaLogo.png`}",
              "foundingDate": "2017",
              "founders": [
                {
                "@type": "Person",
                "name": "Robert Owen"
                } 
              ],
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "11 Chews Lane",
                "addressLocality": "Willis Street",
                "addressRegion": "Wellington",
                "postalCode": "6143",
                "addressCountry": "New Zealand"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "contactType": "Customer Support",
                "email": "support@karmly.com"
              },
              "sameAs": [ 
                "https://twitter.com/invoxy",
                "https://www.linkedin.com/company/invoxy/"
              ]
            }
          `}
      </script>
    </Helmet>
    {/* <Container className="-whiteOut" bg="Secondary">
      <Wrapper stackGap={70}>
        <Row
          className="-center"
          css={{ maxWidth: "800px" }}
          stackGap={50}
          mobileReverse
          alignCenter
        >
          <Box size={40}>
            <Image
              filename="cafe_series_machine.png"
              alt="Karmly"
              addShadow
              centerImage
            />
          </Box>
          <Box stackGap={20} size={60} centerOnMobile>
            <Box stackGap={20}>
              <img src={KarmlyPlusInvoxy} alt="Karmly + Invoxy" height="35" />
              <Box stackGap={10}>
                <h2>Win a coffee machine for your home office!</h2>
                <p>
                  Be one of the first 200 contractors to use Karmly for your
                  Invoxy timesheets and go in the draw to win a Sunbeam Café
                  Series Duo Espresso coffee machine!
                </p>
              </Box>
            </Box>
            <Button
              className="primary -md -centerOnMobile"
              to="https://www.karmly.io/invoxy?utm_source=invoxy&utm_medium=website"
              atag
              externalLink
            >
              Learn More
            </Button>
          </Box>
        </Row>
      </Wrapper>
    </Container> */}
    {/* <HeroContainer>
      <Wrapper stackGap={70} noPaddingBottom className="hero-wrapper">
        <Box stackGap={30}>
          <Box stackGap={10} css={{ maxWidth: "650px", width: "100%" }}>
            <h2 className="-fontLarge">Scale your contractor desk</h2>
            <h1 className="-fontNormal">
              Timesheet & billing software for recruitment & staffing agencies
            </h1>
          </Box>
          <Button className="primary -lg gtm-cta" to="/recruitment/signup">
            Get Started
          </Button>
        </Box>
        <img
          src={HeroProductImage}
          alt="Karmly"
          css={{ maxWidth: "600px", width: "100%" }}
        />
      </Wrapper>images/home/hero-image.png
    </HeroContainer> */}

    <StaticQuery
      query={graphql`
            query {
              desktop: file(relativePath: { eq: "home/hero-image.jpg" }) { 
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          `}
      render={(data) => {
        const imageData = data.desktop.childImageSharp.fluid;
        return (
          <BackgroundImage
            Tag="section"
            fluid={imageData}
            backgroundColor={`#3F7157`}
            css={{
              backgroundPosition: "top",
            }}
          >
            <HeroContainer>
              {/* <Wrapper stackGap={70} noPaddingBottom className="hero-wrapper"> */}
              <Box stackGap={30}>
                <Box stackGap={10} css={{ maxWidth: "650px", width: "100%" }}>
                  <h2 className="-fontLarge">Scale your contractor desk</h2>
                  <h1 className="-fontNormal">
                    Timesheet & billing software for recruitment & staffing agencies
                  </h1>
                </Box>
                <Button className="primary -lg gtm-cta" to="/recruitment/signup">
                  Get Started
                </Button>
              </Box>
              <img
                src={HeroProductImage}
                className="hero-product-image"
                alt="Karmly"
                css={{ maxWidth: "600px", width: "100%" }}
              />
              {/* </Wrapper> */}
            </HeroContainer>

          </BackgroundImage>
        );
      }}
    />
    <Container>
      <Wrapper>


        <Row className="-textCenter" stackGap={40}>
          <Box size={25}>
            <h3 className=" -fontPrimary -monument">
              <img src={ClockIcon} alt="Karmly | Clock" className="-center" />
              <br></br>

              TIMESHEETS
            </h3>
            <br></br>

            <p>
              Contractors and temps record their hours and request approval
              online. Admin users can quickly add bulk time.
            </p>
          </Box>
          <Box size={25}>
            <h3 className=" -fontPrimary -monument">
              <img src={SendIcon} alt="Karmly | Send" className="-center" />
              <br></br>

              CLIENT APPROVALS
            </h3>
            <br></br>

            <p>
              Clients can view all the details and approve timesheets from an
              email without logging in. We keep a full audit trail of approvals.
            </p>
          </Box>
          <Box size={25}>
            <h3 className=" -fontPrimary -monument">
              <img src={ReceiptIcon} alt="Karmly | Receipt" className="-center" />
              <br></br>

              INVOICING
            </h3>
            <br></br>
            <p>
              Quickly create and send large batches of invoices to multiple
              clients, all tailored to their exact requirements.
            </p>
          </Box>
          <Box size={25}>
            <h3 className=" -fontPrimary -monument">
              <img src={ReportIcon} alt="Karmly | Report" className="-center" />
              <br></br>

              REPORTING
            </h3>
            <br></br>

            <p>
              Get unprecedented visibility into your business performance with
              customisable real time reporting.
            </p>
          </Box>
        </Row>
      </Wrapper>
    </Container>
    <Container css={{ backgroundColor: "#FAF9F8", padding: '50px 0px' }}>
      <Wrapper>
        {/* <hr /> */}

        <Box className="-textCenter" stackGap={5}>
          <h3 className="-monument -fontPrimary">SUPERCHARGE YOUR PLACEMENTS</h3>
          <br></br>
          <h2>Keep your admin costs low and get paid faster</h2>
          <br></br>
          <br></br>

          <h4>
            Everything you need to manage contingent workers, after you’ve made
            the placement.
          </h4>
        </Box>
      </Wrapper>
    </Container>


    <Container>
      <Wrapper>
        <Row stackGap={60} alignCenter>
          <Box size={50} stackGap={40} centerOnMobile>
            <div>
              <h2>Connect the Dots</h2>
              <h4>
                Karmly is your middle office recruitment system in between your
                CRM/ATS, payroll and accounting software, giving you a powerful
                end-to-end recruitment software solution.
              </h4>
            </div>
            <Box stackGap={20}>
              <CheckmarkFeature stackGap={20} alignCenter>
                <IoCheckmarkCircleOutline />
                <p>
                  Get your data flowing between systems in your best of breed
                  tech stack and improve the integrity of your data at every
                  stage.
                </p>
              </CheckmarkFeature>
              <CheckmarkFeature stackGap={20} alignCenter>
                <IoCheckmarkCircleOutline />
                <p>
                  Placements made in your ATS appear in Karmly, ready to invite
                  the candidate to enter time.
                </p>
              </CheckmarkFeature>
              <CheckmarkFeature stackGap={20} alignCenter>
                <IoCheckmarkCircleOutline />
                <p>
                  Automatically send pay details to your payroll system and
                  invoice data to your accounting software.
                </p>
              </CheckmarkFeature>
            </Box>
          </Box>
          <Box size={50}>
            <img
              src={MiddleOffice}
              alt="Middle Office Recruitment Software | Karmly"
              className="-center"
              css={{ maxWidth: "500px", width: "100%" }}
            />
          </Box>
        </Row>
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50}>
            <img
              src={Projection}
              alt="Revenue Projections & Cost Information Reporting | Karmly"
              className="-center"
              css={{ maxWidth: "500px", width: "100%" }}
            />
          </Box>
          <Box size={50} stackGap={40} centerOnMobile>
            <div>
              <h2>Make Data Driven Decisions</h2>
              <h4>
                Karmly is a hub of business critical data with actual revenue
                and cost information on hand at all times.
              </h4>
            </div>
            <Box stackGap={20}>
              <CheckmarkFeature stackGap={20} alignCenter>
                <IoCheckmarkCircleOutline />
                <p>
                  Keep your finger on the pulse with real time data - don’t wait
                  until next month to review performance in your accounting
                  system.
                </p>
              </CheckmarkFeature>
              <CheckmarkFeature stackGap={20} alignCenter>
                <IoCheckmarkCircleOutline />
                <p>
                  Live revenue and cost data helps you understand the
                  profitability of every hour worked by your contingent staff.
                </p>
              </CheckmarkFeature>
              <CheckmarkFeature stackGap={20} alignCenter>
                <IoCheckmarkCircleOutline />
                <p>
                  Get powerful insights as to what's working well in your
                  business and what’s not.
                </p>
              </CheckmarkFeature>
            </Box>
            <Link
              className="link-floating -centerOnMobile"
              to="/recruitment/features/reporting"
            >
              Find Out More
            </Link>
          </Box>
        </Row>
        <Row stackGap={60} alignCenter>
          <Box size={50} stackGap={40} centerOnMobile>
            <div>
              <h2>Focus on recruiting. Automate the rest.</h2>
              <h4>
                Karmly gives your recruitment agency all the tools you need to
                efficiently manage your middle office, including contractor
                onboarding, online timesheets, approvals and invoicing.
              </h4>
            </div>
            <Box stackGap={20}>
              <CheckmarkFeature stackGap={20} alignCenter>
                <IoCheckmarkCircleOutline />
                <p>
                  Streamline your operations, reduce manual intervention and
                  focus on the tasks that need attention.
                </p>
              </CheckmarkFeature>
              <CheckmarkFeature stackGap={20} alignCenter>
                <IoCheckmarkCircleOutline />
                <p>
                  Get paid faster by speeding up every step from timesheet to
                  invoicing.
                </p>
              </CheckmarkFeature>
              <CheckmarkFeature stackGap={20} alignCenter>
                <IoCheckmarkCircleOutline />
                <p>
                  Payroll and invoices are always accurate because they’re
                  generated from approved timesheets with no data entry or
                  manual calculations.
                </p>
              </CheckmarkFeature>
            </Box>
            <Link
              className="link-floating -centerOnMobile"
              to="/recruitment/features/dashboard"
            >
              Find Out More
            </Link>
          </Box>
          <Box size={50}>
            <img
              src={Dashboard}
              alt="Recruitment & Placement Management Made Easy | Karmly"
              className="-center"
              css={{ maxWidth: "500px", width: "100%" }}
            />
          </Box>
        </Row>
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50}>
            <img
              src={Time}
              alt="Online Timesheets for Contractors & Clients | Karmly"
              className="-center"
              css={{ maxWidth: "500px", width: "100%" }}
            />
          </Box>
          <Box size={50} stackGap={40} centerOnMobile>
            <div>
              <h2>Make an Impression</h2>
              <h4>
                We’ve designed Karmly with user experience at the forefront of
                every decision. It’s beautifully simple for you, your clients
                and your contractors to use.
              </h4>
            </div>
            <Box stackGap={20}>
              <CheckmarkFeature stackGap={20} alignCenter>
                <IoCheckmarkCircleOutline />
                <p>
                  Enhance the candidate and client experience with professional,
                  easy to use software showcasing your brand.
                </p>
              </CheckmarkFeature>
              <CheckmarkFeature stackGap={20} alignCenter>
                <IoCheckmarkCircleOutline />
                <p>
                  Automate compliance and induction tasks with customised
                  contractor onboarding.
                </p>
              </CheckmarkFeature>
              <CheckmarkFeature stackGap={20} alignCenter>
                <IoCheckmarkCircleOutline />
                <p>
                  Flexible timesheet options and a contractor mobile app make it
                  easy and intuitive for everyone to log their time.
                </p>
              </CheckmarkFeature>
            </Box>
            <Link
              className="link-floating -centerOnMobile"
              to="/recruitment/features/timesheets"
            >
              Find Out More
            </Link>
          </Box>
        </Row>
      </Wrapper>
    </Container>

    <Container className="inv-orange">
      <Wrapper>
        <Box className="-textCenter" stackGap={5}>
          <h3 className="-monument" css={{ color: "#CFF0D3 !important" }}>BETTER TOGETHER</h3>
          <br></br>
          <h2>Powerful Integrations</h2>
          <br></br>

          <h4>
            Connect Karmly with the best industry apps for a slick end-to-end
            recruitment software solution.
          </h4>
        </Box>
        <LogoRow stackGap={30} alignCenter>
          <div>
            <img
              src={APositiveLogo}
              alt="APositive Invoice Finance | Karmly Integration"
            />
          </div>
          <div>
            <img
              src={BullhornLogo}
              alt="Bullhorn Applicant Tracking | Karmly Integration"
            />
          </div>
          <div>
            <img
              src={JobAdderLogo}
              alt="JobAdder Recruitment Management | Karmly Integration"
            />
          </div>
          <div>
            <img
              src={EmploymentHeroLogo}
              alt="Employment Hero Australian Payroll | Karmly Integration"
            />
          </div>
          <div>
            <img
              src={PayHeroLogo}
              alt="PayHero New Zealand Payroll| Karmly Integration"
            />
          </div>
          <div>
            <img
              src={PowerBILogo}
              alt="PowerBI Reporting | Karmly Integration"
            />
          </div>
          <div>
            <img
              src={XeroLogo}
              alt="Xero Accounting & Payroll | Karmly Integration"
            />
          </div>
        </LogoRow>
        <Link to="/recruitment/integrations" className="link-floating link-white -center">
          View all Integrations
        </Link>
      </Wrapper>
    </Container>
    <Container css={{ backgroundColor: "#2C3832", color: '#CFF0D3' }}>
      <Wrapper>
        <Box stackGap={50}>
          <Box className="-textCenter" stackGap={5}>
            <h3 className="-monument" css={{ color: "#CFF0D3" }}>GET STARTED</h3>
            <br></br>
            <h2>Ready to streamline operations & increase margins?</h2>
          </Box>
          <Button className="primary -lg -center gtm-cta" to="/recruitment/signup">
            Get Started
          </Button>
        </Box>
      </Wrapper>
    </Container>
  </Layout >
);

export default IndexPage;
